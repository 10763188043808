import GastrologikLogo from "../../images/gastrologik-logo.png"

export function blogSchemaMarkup(
  metaTitle,
  metaDescription,
  slug,
  mainImage,
  publishedAt
) {
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://gastrologik.dk/" + slug.current + "/",
    },
    headline: metaTitle,
    description: metaDescription,
    image: mainImage.asset.url,
    author: {
      "@type": "Organization",
      name: "Gastrologik",
      url: "https://www.gastrologik.dk/",
    },
    publisher: {
      "@type": "Organization",
      name: "Gastrologik",
      logo: {
        "@type": "ImageObject",
        url: GastrologikLogo,
      },
    },
    datePublished: publishedAt,
    dateModified: publishedAt,
  }
  return blogSchema
}

export function breadCrumbMarkup(slug, page) {
  const breadCrumbPost = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "gastrologik.dk",
        item: "https://www.gastrologik.dk/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "blog",
        item: "https://www.gastrologik.dk/" + page + "/",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: slug.current,
        item: "https://gastrologik.dk/" + slug.current + "/",
      },
    ],
  }
  return breadCrumbPost
}
