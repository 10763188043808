import { Divider } from "@chakra-ui/react"
import React from "react"
import SoMe from "./SoMe"

const SimpleFooter = () => (
  <footer>
    <div className="simpleFooter">
      <Divider mt={4} />
      <div className="some">
        <SoMe />
      </div>
    </div>
  </footer>
)

export default SimpleFooter
