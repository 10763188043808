import React from "react"
import PropTypes from "prop-types"

import "../../styles/style.scss"
import SubHeader from "../header/SubHeader"

const PostLayout = ({ children, showFooter }) => {
  return (
    <>
      <SubHeader />
      <main className="mainContent">{children}</main>
    </>
  )
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostLayout
