import React, { useEffect } from "react"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
import SimpleFooter from "../components/global/SimpleFooter"
import { blogSchemaMarkup, breadCrumbMarkup } from "../utils/schemas/blogSchema"
import ExtractText from "../components/sanity/ExtractText"
import PostLayout from "../components/global/PostLayout"

const Post = ({ data }) => {
  const { mainImage, slug, publishedAt, svg, title, _rawBody, seo } =
    data.sanityPost

  const metaTitle = !seo ? title : seo.seoTitle
  const metaDescription = !seo
    ? ExtractText(_rawBody).substring(0, 155)
    : seo.seoTitle

  const slugPrefix = "blog"
  const page = "alle-opslag"

  // InterActive

  useEffect(() => {
    let allContainers = document.querySelectorAll(".floatingID")
    allContainers.forEach((container, index) => {
      index++
      container.setAttribute("id", "container" + index)
    })
  })

  return (
    <PostLayout showFooter={false}>
      <article className="post postLayout">
        <SearchEngineOptimizaion
          title={metaTitle}
          description={metaDescription}
          blogMarkup={blogSchemaMarkup(
            metaTitle,
            metaDescription,
            slug,
            mainImage,
            publishedAt,
          )}
          breadCrumbPost={breadCrumbMarkup(slug, page, slugPrefix)}
        />
        {svg == null ? (
          <div className="verticalImageContainer">
            <GatsbyImage
              image={mainImage.asset.gatsbyImageData}
              alt={title}
              className="verticalImage "
              loading="lazy"
            />
          </div>
        ) : (
          <></>
          // <div className="interactiveElement">
          //   <div
          //     onClick={e => {
          //       let value = e.target.dataset.name
          //       scrollTo("#container" + value)
          //       if (value !== undefined) {
          //         colorizeSVG(value)
          //       }
          //     }}
          //     dangerouslySetInnerHTML={{ __html: data.sanityPost.svg.svgUpload }}
          //   />
          // </div>
        )}

        <div className="rightWrapper hideScrollbar">
          <div className="titleWrapper  blogPostTitle padding">
            {svg == null ? (
              <></>
            ) : (
              <GatsbyImage
                image={mainImage.asset.gatsbyImageData}
                alt={title}
                className="horisontalImage"
                loading="auto"
              />
            )}
            <h1>{title}</h1>
            <div className="spanLineLeft"></div>
          </div>
          <BlockContent
            blocks={_rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent padding"
          />
          <div className="divider"></div>
          <SimpleFooter soMeLinks={data.sanityCompanyInfo} />
        </div>
      </article>
    </PostLayout>
  )
}

export default Post

export const query = graphql`
  query ($Slug: String) {
    sanityPost(slug: { current: { eq: $Slug } }) {
      slug {
        current
      }
      mainImage {
        asset {
          url
          gatsbyImageData(
            backgroundColor: "#f9f9f9"
            placeholder: NONE
            width: 300
          )
        }
      }
      seo {
        seoTitle
        seoDescription
      }
      title

      publishedAt(formatString: "YYYY-MM-DD")

      _rawBody(resolveReferences: { maxDepth: 10 })

      svg {
        favoriteColor {
          hex
        }
        svgUpload
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    sanityCompanyInfo {
      facebook
      instragram
      pinterest
    }
  }
`
